import React from 'react';

import Head from 'next/head';

import { Button, Col, Container, Row } from 'reactstrap';

import stylesheet from '../styles/index.scss';
import styleDLS from '../styles/ProLayout.module.scss';

export default function Custom404() {
  return (
    <>
      <Head>
        <title>404 Page Not Found</title>
        <meta
          name="description"
          content="Halaman Tidak Ditemukan, Maaf, halaman yang dituju tidak tersedia. Mohon cek ulang tautan pada browser atau hubungi"
        />
        <meta name="keywords" content="hukum online" />
        <link rel="canonical" href="https://www.hukumonline.com/404/" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="404 Page Not Found" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/static/images/404.png" />
        <meta property="og:url" content="https://www.hukumonline.com/404/" />
        <meta
          property="og:description"
          content="Halaman Tidak Ditemukan, Maaf, halaman yang dituju tidak tersedia. Mohon cek ulang tautan pada browser atau hubungi"
        />
      </Head>
      <div className="bg-hol-light">
        <Container>
          <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
          <style dangerouslySetInnerHTML={{ __html: styleDLS }} />
          <div className="bg-hol-light vh-100">
            <Row className="py-5 d-flex align-items-center justify-content-center bg-hol-light">
              <Col md={5} className="mx-auto">
                <div className="d-flex flex-column align-center justify-content-center text-center">
                  <div className="my-3">
                    <img
                      src="/static/images/404.png"
                      className="img-fluid"
                      alt="404 - Halaman tidak ditemukan"
                      title="hukumonline.com - Halaman tidak ditemukan"
                    />
                  </div>
                  <div>
                    <h1 className="header-3 text-primary font-weight-bold">
                      Halaman tidak ditemukan
                    </h1>
                    <p className="paragraph text-muted font-weight-medium">
                      Maaf, halaman yang dituju tidak tersedia. Mohon cek ulang
                      tautan pada browser atau hubungi{' '}
                      <a
                        href="mailto:customer@hukumonline.com"
                        className="body text-primary font-weight-medium"
                      >
                        customer@hukumonline.com
                      </a>
                    </p>
                  </div>
                  <div className="my-3">
                    <Button
                      color="primary"
                      outline
                      href="/"
                      className="body font-weight-semibold"
                    >
                      Halaman Utama
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
